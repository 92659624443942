import React, { useState, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { Slider, CustomDot, CustomPagination } from 'landing/ui';

import * as styles from './ServicesSlider.module.scss';
import 'landing/ui/lib/Slider/globalTools.scss';

export default function ServicesSlider({ items, Card }) {
  const [state, setState] = useState({
    loading: false,
    current: 0,
  });

  const handleBeforeChange = useCallback((prev, next) => {
    setState(prev => ({ ...prev, current: next, allowHint: false }));
  }, []);

  const handleAfterChange = useCallback(() => {
    setState(prev => ({ ...prev, allowHint: true }));
  }, []);

  const settings = useMemo(
    () => ({
      customPaging: CustomDot.bind(null, items?.length, state.current),
      appendDots: CustomPagination,
      dotsClass: 'slick-pagination',
      autoplay: true,
      initialSlide: 0,
      autoplaySpeed: 10000,
      dots: true,
      infinite: true,
      lazyLoad: 'progressive',
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 544,
          settings: {
            adaptiveHeight: true,
            arrows: true,
          },
        },
      ],
    }),
    [items?.length, state.current],
  );

  return (
    <div className={cn(styles.cardsWrapper, 'slider-wrapper', 'tools-slider')}>
      <Slider {...settings} beforeChange={handleBeforeChange} afterChange={handleAfterChange}>
        {items?.map((item, i) => (
          <Card key={i} {...item} />
        ))}
      </Slider>
    </div>
  );
}

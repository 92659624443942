import React from 'react';
import cn from 'classnames';

import { Container, Title, Markup, Image } from 'landing/ui';
import ExamplesGrid from './ExamplesGrid';
import ServicesSlider from './ServicesSlider';
import * as styles from './Examples.module.scss';

export default function Examples({ title, items, background = 'white', id = 'examples' }) {
  return (
    <>
      <Container background={background} id={id}>
        <div className={styles.wrapper}>
          <Title tag="h2" className={cn(styles.title)}>
            {title}
          </Title>
          <div className={styles.layout}>
            <ExamplesGrid Card={ServicesItem} items={items} />
            <ServicesSlider Card={ServicesItem} items={items} />
          </div>
        </div>
      </Container>
    </>
  );
}

const ServicesItem = ({ theme, title, description, img, name, isReversed }) => {
  return (
    <div
      className={cn(styles.item, styles[`${theme}TH`], isReversed && styles.itemReversed)}
      data-aos={isReversed ? 'fade-left' : 'fade-right'}
    >
      <div className={cn(isReversed && styles.textWrapperReversed)}>
        <div className={styles.itemName}>{name}</div>
        <Title tag="h3" size="h4" color="white" className={styles.itemTitle}>
          {title}
        </Title>
        {description && (
          <Markup className={styles.description} tag="p">
            {description}
          </Markup>
        )}
      </div>

      <div className={styles.itemImage}>
        <Image src={img} alt="background" lazy />
      </div>
    </div>
  );
};

import React, { useEffect } from 'react';
import AOS from 'aos';

import { Footer, Head, Header } from 'landing/components';
import WhatWeDo from 'components/WhatWeDo';
import Services from 'components/Services';
import Examples from 'components/Examples';
import Intro from 'components/Intro';
import { GetEstimation } from 'components/GetConsultation';
import CookiesPolicy from 'components/CookiesPolicy';
import About from 'components/About';

import { anchorLinks } from 'define/anchorLinks';

import data from './data.json';

import 'aos/dist/aos.css';

function Index() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
    });
  }, []);

  return (
    <>
      <Head {...data.head} />
      <Header anchorLinks={anchorLinks} />
      <Intro {...data.intro} />
      <Services {...data.services} />
      <Examples {...data.examples} />
      <GetEstimation {...data.getEstimation} />
      <About {...data.about} />
      <WhatWeDo {...data.whatWeDo} />
      <Footer {...data.footer} />
      <CookiesPolicy />
    </>
  );
}

export default Index;

import React from 'react';
import cn from 'classnames';

import { Container, Title, Subtitle, Grid, GridItem, Link, Markup, Icon } from 'landing/ui';

import * as styles from './WhatWeDo.module.scss';

export default function WhatWeDo({
  title,
  subtitle,
  items,
  theme = '',
  background = 'darkPrimary',
  subtitleWidth,
  colsXsm = 1,
  colsSm = 3,
  colsMd = 3,
  colsLg = 3,
  colsXl = 3,
  fullHd = 3,
  id = 'advantages',
}) {
  return (
    <>
      <Container background={background} id={id} className={styles.container}>
        <div className={styles.wrapper}>
          <Title tag="h2" className={cn(styles.title)}>
            {title}
          </Title>
          {subtitle && (
            <Subtitle
              className={cn(
                styles.subtitle,
                theme === 'light' && styles.subtitleLight,
                subtitleWidth && styles.subtitleWidth,
              )}
              size="md"
            >
              {subtitle}
            </Subtitle>
          )}
          <div className={styles.layout}>
            <div className={styles.gridWrapper}>
              {items.map((item, i) => (
                <div
                  className={cn(styles.item, theme === 'light' && styles.itemLight)}
                  key={item.icon}
                  data-aos={i !== 2 ? 'fade-right' : 'fade-left'}
                >
                  <div>
                    <div className={styles.iconWrapper}>
                      <Icon name={item.icon} />
                    </div>
                    <Title tag="h3" size="h4" color="white" className={styles.itemTitle}>
                      {item.title}
                    </Title>
                    <Title tag="h3" size="h5" className={styles.itemSubtitle}>
                      {item.subtitle}
                    </Title>
                    {item.description && (
                      <Markup className={styles.description} tag="p">
                        {item.description}
                      </Markup>
                    )}
                  </div>
                  {item.iconsList && (
                    <ul className={cn(styles.list, styles[`list${i + 1}`])}>
                      {item.iconsList.map((listItem, i) => (
                        <li key={i} className={styles.checkedItem}>
                          {item.listHasIcons ? <Icon name={listItem} /> : <span>{listItem}</span>}
                        </li>
                      ))}
                    </ul>
                  )}
                  {/*  <div className={cn(item.link && styles.linkWrapper)}>
                    {item.link && (
                      <Link
                        className={styles.link}
                        href={item.link}
                        target={item.target}
                        state={{ internal: true }}
                      ></Link>
                    )}
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

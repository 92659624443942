import React from 'react';
import { Title } from 'landing/ui';
import * as styles from './ProcessSteps.module.scss';

export default function ProcessSteps({ title, steps }) {
  return (
    <section className={styles.wrapper}>
      {title && (
        <Title tag="p" size="h4" className={styles.title}>
          {title}
        </Title>
      )}
      <div className={styles.grid}>
        {steps?.map((step, index) => (
          <div
            key={step}
            className={styles.step}
            data-aos="fade-right"
            data-aos-delay={`${(index + 1) * 500}`}
          >
            <div className={styles.circle}>{index + 1}</div>
            <p className={styles.stepTitle}>{step}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

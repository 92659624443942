import React from 'react';
import cn from 'classnames';

import { Container, Image, Icon, Markup } from 'landing/ui';
import * as styles from './About.module.scss';

export default function About({ title, company, icon, contacts, img }) {
  return (
    <Container background="variable" id="about">
      <div className={styles.wrapper} data-aos="fade-up">
        <div className={styles.text}>
          <p className={styles.title}>{title}</p>
          <div className={styles.subtitle}>
            AstraTrade уже год решает бизнес-задачи с помощью технологий <span>Ms SQL</span>,{' '}
            <span>Java</span>, <span>React.JS</span>, <span>Bamboo</span>, <span>Docker</span>,{' '}
            <span>Kafka</span> и прочих для таких клиентов, как <span>Альфа-банк</span> и{' '}
            <span>BSS</span>
          </div>
          <Contacts {...contacts} />
        </div>
        <div className={cn(styles.itemImage)}>
          <Image src={img.src} alt="background" lazy />
        </div>
      </div>
    </Container>
  );
}

const WrappedIcon = ({ icon, className }) => (
  <div className={cn(className, styles.iconWrapper)}>
    <div className={cn(className, styles.icon)}>
      <Icon name={icon} />
    </div>
  </div>
);

const Contacts = ({ title, icons }) => {
  const ContactWrapper = ({ href, children }) => {
    let tag;
    if (href) {
      tag = 'a';
    } else {
      tag = 'div';
    }

    return (
      <Markup tag={tag} href={href} className={styles.text}>
        {children}
      </Markup>
    );
  };

  return (
    <div className={styles.contactsWrapper}>
      <div>
        {icons.map(({ img, description, href, title }) => (
          <>
            <div className={styles.contact} key={img}>
              <WrappedIcon icon={img} />
              <div className={styles.description}>{title}</div>
            </div>
            <div className={styles.textWrapper}>
              {description.map((text, i) => (
                <ContactWrapper href={href?.[i] || ''} key={text}>
                  {text}
                </ContactWrapper>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

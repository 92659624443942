// extracted by mini-css-extract-plugin
export var box = "Examples-module--box--cee8b";
export var description = "Examples-module--description--e90b8";
export var item = "Examples-module--item--77bcb";
export var itemImage = "Examples-module--itemImage--d147a";
export var itemName = "Examples-module--itemName--9ab0d";
export var itemReversed = "Examples-module--itemReversed--afe09";
export var itemTitle = "Examples-module--itemTitle--b4c25";
export var layout = "Examples-module--layout--fbc90";
export var textWrapperReversed = "Examples-module--textWrapperReversed--0814f";
export var title = "Examples-module--title--63f06";
export var wrapper = "Examples-module--wrapper--e39d5";
import React from 'react';

import { Grid, GridItem } from 'landing/ui';
import * as styles from './ServicesGrid.module.scss';

export default function ServicesGrid({
  items,
  colsXsm = 1,
  colsSm = 2,
  colsMd = 2,
  colsLg = 2,
  colsXl = 2,
  fullHd = 2,
  Card,
}) {
  return (
    <>
      <Grid
        xsm={{ cols: colsXsm, gutterY: '28px' }}
        sm={{ cols: colsSm }}
        md={{ cols: colsMd }}
        lg={{ cols: colsLg, gutterX: '30px', gutterY: '30px' }}
        xl={{ cols: colsXl, gutterX: '40px', gutterY: '40px' }}
        fullHd={{ cols: fullHd }}
        customClassName={styles.cardsWrapper}
      >
        {items.map((item, i) => (
          <GridItem key={i}>
            <Card {...item} i={i} />
          </GridItem>
        ))}
      </Grid>
    </>
  );
}

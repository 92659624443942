export const anchorLinks = [{
    anchor: 'services',
    alias: '#services',
    title: 'Услуги',
  },
  {
    anchor: 'examples',
    alias: '#examples',
    title: 'Примеры работ',
  },
  {
    anchor: 'about',
    alias: '#about',
    title: 'О компании',
  },
  {
    anchor: 'advantages',
    alias: '#advantages',
    title: 'Наши преимущества',
  },
];